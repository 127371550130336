import { Box, Card, CardBody, Text } from '@chakra-ui/react'
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
} from '@chakra-ui/react'

function TraderOrders() {

    const orders = [];

    return (
        <Box mt={1}>
            <Card bgColor={'rgb(19, 23, 34)'} color={'whiteAlpha.800'} m={2} shadow={'dark-lg'}>
                <CardBody>
                    <Box h={'240px'} overflow={'auto'}>
                        <Text mb={5} borderBottom={'solid 1px rgba(250,250,250, 0.4)'} pb={2}> Opened Orders (0)</Text>
                        {
                            orders.length > 0 ?
                                <TableContainer>
                                    <Table size={'sm'}>
                                        <TableCaption> Your opened positions will appear here. </TableCaption>
                                        <Thead>
                                            <Tr>
                                                <Th>Time</Th>
                                                <Th>Pair</Th>
                                                <Th>Action</Th>
                                                <Th>Opening Value</Th>
                                                <Th>P/L</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody color={'whiteAlpha.700'}>
                                            <Tr>
                                                <Td>30/4/2023 10:26</Td>
                                                <Td>BTC-USDT</Td>
                                                <Td>Buy</Td>
                                                <Td>$26,300</Td>
                                                <Td>$92</Td>
                                            </Tr>
                                            <Tr>
                                                <Td>30/4/2023 10:26</Td>
                                                <Td>BTC-USDT</Td>
                                                <Td>Buy</Td>
                                                <Td>$26,300</Td>
                                                <Td>$92</Td>
                                            </Tr>
                                        </Tbody>
                                    </Table>
                                </TableContainer> :
                                <Box fontSize={'13px'}>Your opened positions will appear here.</Box>
                        }
                    </Box>
                </CardBody>
            </Card>
        </Box>
    )
}

export default TraderOrders