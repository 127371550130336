import { Square, Image, Text, Box } from '@chakra-ui/react'
import profile from '../assets/images/profile.png'


function Profile() {
    return (
        <>
            <Square h={'500px'} mt={{ base: '0px', md: '100px' }}>
                <Box>
                    <Text textAlign={'center'} pb={3} borderBottom={'solid 1px rgba(230, 230, 230, 0.2)'}>Sign in to access ths page !</Text><br />
                    <Image src={profile} w={{ base: '300px', md: '500px' }} />
                </Box>
            </Square>
        </>
    )
}

export default Profile