// TvCryptoMarket.jsx
import React, { useEffect, useRef, memo } from 'react';

function TvCryptoMarket() {
  const contariner = useRef();
  // const isRendered = useRef(false)

  useEffect(
    () => {
      // if (isRendered.current === true) {
        const script = document.createElement("script");
        script.src = "https://s3.tradingview.com/external-embedding/embed-widget-screener.js";
        script.type = "text/javascript";
        script.async = true;
        script.innerHTML = `{
            "width": "100%",
            "height": 490,
            "defaultColumn": "overview",
            "screener_type": "crypto_mkt",
            "displayCurrency": "USD",
            "colorTheme": "dark",
            "locale": "en"
          }`;
        contariner.current.appendChild(script);
      // }else {
      //   isRendered.current = true;
      // }
    },
    []
  );

  return (
    <div className="tradingview-widget-container" ref={contariner}>
      <div className="tradingview-widget-container__widget"></div>
    </div>
  );
}

export default memo(TvCryptoMarket);
