// TvNews.jsx
import React, { useEffect, useRef, memo } from 'react';

function TvNews() {
  const contariner = useRef();
  // const isRendered = useRef(false)

  useEffect(
    () => {
      // if (isRendered.current === true) {
        const script = document.createElement("script");
        script.src = "https://s3.tradingview.com/external-embedding/embed-widget-timeline.js";
        script.type = "text/javascript";
        script.async = true;
        script.innerHTML = `{
          "feedMode": "market",
          "market": "crypto",
          "colorTheme": "dark",
          "isTransparent": false,
          "isTransparent": true,
          "displayMode": "regular",
          "width": "100%",
          "height": "600",
          "locale": "en"
        }`;
        contariner.current.appendChild(script);
      // }else {
      //   isRendered.current = true;
      // }
    },
    []
  );

  return (
    <div className="tradingview-widget-container" ref={contariner}>
      <div className="tradingview-widget-container__widget"></div>
    </div>
  );
}

export default memo(TvNews);
