import { Box, Text, useDisclosure } from '@chakra-ui/react'
import { useRef } from 'react'
import { FiPlusCircle } from 'react-icons/fi'

import {
    Drawer,
    DrawerBody,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
} from '@chakra-ui/react'
import TradeCard from './TradeCard'

function MobileTradeDrawer({ symbol }) {

    const { isOpen, onOpen, onClose } = useDisclosure()
    const btnRef = useRef()

    return (
        <>
            <Box w={'100%'} display={'flex'}>

                <Box fontSize={'29px'} mr={2}>
                    <Box bgColor={'rgb(19, 23, 34)'} shadow={'dark-lg'} borderRadius={'100%'} onClick={onOpen}>
                        <FiPlusCircle />
                    </Box>
                </Box>

                <Box bgColor={'blue.900'} shadow={'dark-lg'} borderRadius={'10px'} p={2} onClick={onOpen}>
                    <Text fontSize={'11px'}>Click to</Text>
                    <Text fontSize={'13.5px'}>Trade {symbol.toUpperCase()}</Text>
                </Box>
            </Box>

            <Drawer
                isOpen={isOpen}
                placement='bottom'
                onClose={onClose}
                finalFocusRef={btnRef}
            >
                <DrawerOverlay />
                <DrawerContent bgColor={'rgb(19, 23, 34)'} borderTopRightRadius={'20px'} borderTopLeftRadius={'20px'}>
                    <DrawerCloseButton color={'whiteAlpha.700'} />
                    <DrawerHeader color={'#fff'}><Text fontSize={'14px'}>Trade {symbol.toUpperCase()}</Text></DrawerHeader>

                    <DrawerBody>
                        <Box h={'auto'} pb={5}>
                            <TradeCard />
                        </Box>
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </>
    )
}

export default MobileTradeDrawer