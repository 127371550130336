import { Box, Card, CardBody, Input, Text, Icon } from '@chakra-ui/react'
import {
    Table,
    Tbody,
    Tr,
    Td,
    TableContainer,
} from '@chakra-ui/react'
import { FaStar } from 'react-icons/fa'

function Watchlist() {
    const watchlist = []
    return (
        <Box>
            <Card bgColor={'rgb(19, 23, 34)'} color={'whiteAlpha.800'} shadow={'dark-lg'}>
                <CardBody>
                    <Box h={'400px'} overflow={'auto'}>
                        <Text>Watchlist</Text>

                        <Box mt={3}>
                            <Input variant={'flushed'} bgColor={'rgba(255,255,255,0.1)'} p={'2px 10px'} placeholder={'BTCUSDT'} />

                            <Box mt={3}>
                                {
                                    watchlist.length > 0 ?
                                        <Box>
                                            Hello
                                        </Box> :
                                        <Box fontSize={'13px'}>
                                            <Text>Saved pairs will appear here.</Text>

                                            <Box mt={5}>
                                                <Text borderBottom={'solid 1px #fff'} display={'inline-block'} mb={3}>Popular</Text>

                                                <TableContainer>
                                                    <Table variant='unstyled' size={'sm'}>
                                                        <Tbody>
                                                            <Tr _hover={{ bgColor: 'rgba(240,240,240,0.1)' }} borderBottom={'solid 1px rgba(230,230,230,0.15)'}>
                                                                <Td textAlign={'left'} fontSize={'12px'}>BTC/USDT</Td>
                                                                <Td textAlign={'right'} fontSize={'12px'}><Icon as={FaStar}/></Td>
                                                            </Tr>
                                                            <Tr _hover={{ bgColor: 'rgba(240,240,240,0.1)' }} borderBottom={'solid 1px rgba(230,230,230,0.15)'}>
                                                                <Td textAlign={'left'} fontSize={'12px'}>ETH/USDT</Td>
                                                                <Td textAlign={'right'} fontSize={'12px'}><Icon as={FaStar} color={'orange.400'}/></Td>
                                                            </Tr>
                                                            <Tr _hover={{ bgColor: 'rgba(240,240,240,0.1)' }} borderBottom={'solid 1px rgba(230,230,230,0.15)'}>
                                                                <Td textAlign={'left'} fontSize={'12px'}>AAVE/USDT</Td>
                                                                <Td textAlign={'right'} fontSize={'12px'}><Icon as={FaStar} color={'orange.400'}/></Td>
                                                            </Tr>
                                                            <Tr _hover={{ bgColor: 'rgba(240,240,240,0.1)' }} borderBottom={'solid 1px rgba(230,230,230,0.15)'}>
                                                                <Td textAlign={'left'} fontSize={'12px'}>ETH/BTC</Td>
                                                                <Td textAlign={'right'} fontSize={'12px'}><Icon as={FaStar} color={'orange.400'}/></Td>
                                                            </Tr>
                                                            <Tr _hover={{ bgColor: 'rgba(240,240,240,0.1)' }} borderBottom={'solid 1px rgba(230,230,230,0.15)'}>
                                                                <Td textAlign={'left'} fontSize={'12px'}>UNI/ETH</Td>
                                                                <Td textAlign={'right'} fontSize={'12px'}><Icon as={FaStar} color={'orange.400'}/></Td>
                                                            </Tr>
                                                        </Tbody>
                                                    </Table>
                                                </TableContainer>
                                            </Box>
                                        </Box>
                                }
                            </Box>
                        </Box>
                    </Box>
                </CardBody>
            </Card>
        </Box>
    )
}

export default Watchlist