import { NavLink } from 'react-router-dom'
import { Box, Center, Flex, Show, Text, Icon } from '@chakra-ui/react'

import { TbDeviceAnalytics, TbSettings, TbWallet } from 'react-icons/tb'
import { MdOutlineSell } from 'react-icons/md'
import { AiOutlinePieChart } from 'react-icons/ai'

import style from '../assets/modules/navheader.module.css'

function NavHeader() {
    return (
        <>
            <Box bgColor={'rgb(19, 23, 34)'} pos={'fixed'} p={5} w={'100%'} shadow={'xl'} zIndex={1}>
                <Flex justifyContent={'space-between'}>
                    <Box textDecoration={'underline'} textDecorationThickness={'4px'} textDecorationColor={'cyan.700'} fontSize={'17px'}>Crypto.com</Box>
                    <Show above={'md'}>
                        <Flex color={'whiteAlpha.600'} mt={2}>
                            <NavLink className={({isActive}) => (isActive && style.active)} to={'/user/trader'}><Box p={"0px 5px"} m={"0px 5px"}><div className='activee'>Trader</div></Box></NavLink>
                            <NavLink className={({isActive}) => (isActive && style.active)} to={'/user/analytics'}><Box p={"0px 5px"} m={"0px 5px"}>Analytics</Box></NavLink>
                            <NavLink className={({isActive}) => (isActive && style.active)} to={'/user/orders'}><Box p={"0px 5px"} m={"0px 5px"}>Orders</Box></NavLink>
                            <NavLink className={({isActive}) => (isActive && style.active)} to={'/user/wallet'}><Box p={"0px 5px"} m={"0px 5px"}>Wallet</Box></NavLink>
                            <NavLink className={({isActive}) => (isActive && style.active)} to={'/user/account'}><Box p={"0px 5px"} m={"0px 5px"}>Account management</Box></NavLink>
                        </Flex>
                    </Show>
                    <Flex>
                        <Box textAlign={'right'} mr={4}>
                            <Text fontSize={10}>PRACTICE ACCOUNT</Text>
                            <Text>$10.003.13</Text>
                        </Box>
                        <Box>
                            <Center w={'35px'} h={'35px'} bgColor={'cyan.700'} borderRadius={'100%'} fontSize={'18px'} fontWeight={'bold'}>A</Center>
                        </Box>
                    </Flex>
                </Flex>
            </Box>

            <Box
                w={'100%'} h={'auto'}
                bgColor={'rgb(19, 23, 34)'}
                pos={'fixed'} bottom={'0px'} left={'0px'} zIndex={'3'}
                display={{ base: 'block', md: 'none' }}
                shadow={'dark-lg'}>

                <Flex>
                    <Box color={'whiteAlpha.600'} flex={1} textAlign={'center'} p={"10px 5px"} m={"0px 5px"}>
                        <NavLink className={({isActive}) => (isActive && style.active)} to={'/user/analytics'}>
                            <Icon fontSize={'28px'} as={AiOutlinePieChart} /><br />
                            <Text fontSize={'11px'}>Analytics</Text>
                        </NavLink>
                    </Box>
                    <Box color={'whiteAlpha.600'} flex={1} textAlign={'center'} p={"10px 5px"} m={"0px 5px"}>
                        <NavLink className={({isActive}) => (isActive && style.active)} to={'/user/orders'}>
                            <Icon fontSize={'28px'} as={MdOutlineSell} /><br />
                            <Text fontSize={'11px'}>Orders</Text>
                        </NavLink>
                    </Box>
                    <Box color={'whiteAlpha.600'} flex={1} textAlign={'center'} p={"10px 5px"} m={"0px 5px"}>
                        <NavLink className={({isActive}) => (isActive && style.active)} to={'/user/trader'}>
                            <Icon fontSize={'28px'} as={TbDeviceAnalytics} /><br />
                            <Text fontSize={'11px'} fontWeight={'bold'}>Trader</Text>
                        </NavLink>
                    </Box>
                    <Box color={'whiteAlpha.600'} flex={1} textAlign={'center'} p={"10px 5px"} m={"0px 5px"}>
                        <NavLink className={({isActive}) => (isActive && style.active)} to={'/user/wallet'}>
                            <Icon fontSize={'28px'} as={TbWallet} /><br />
                            <Text fontSize={'11px'}>Wallet</Text>
                        </NavLink>
                    </Box>
                    <Box color={'whiteAlpha.600'} flex={1} textAlign={'center'} p={"10px 5px"} m={"0px 5px"}>
                        <NavLink className={({isActive}) => (isActive && style.active)} to={'/user/account'}>
                            <Icon fontSize={'28px'} as={TbSettings} /><br />
                            <Text fontSize={'11px'}>Account</Text>
                        </NavLink>
                    </Box>
                </Flex>
            </Box>

        </>
    )
}

export default NavHeader