
import React, { useEffect, useRef, useState } from 'react'
import { Box, Grid, GridItem, Hide } from '@chakra-ui/react'
import TvMainChart from '../../components/tv/TvMainChart'
import TradeCard from '../../components/TradeCard'
import TraderSymbolHeader from '../../components/TraderSymbolHeader'
import OrderBook from '../../components/OrderBook'
import TraderOrders from '../../components/TraderOrders'
import Watchlist from '../../components/Watchlist'

import MobileMarketInfoDrawer from '../../components/MobileMarketInfoDrawer'
import MobileTradeDrawer from '../../components/MobileTradeDrawer'

function Trader() {

  const symbol = 'btcusdt';

  const ws = useRef()
  // const [symbol, setSymbol] = useState('ethbtc')
  const [orderBook, setOrderBook] = useState(null)
  const [symbol24HrTicker, setSymbol24HrTicker] = useState(null)
  // const isRendered = useRef(false)

  useEffect(() => {
    // if (isRendered.current) {

      ws.current = new WebSocket(`wss://stream.binance.com:9443/stream?streams=${symbol}@ticker/${symbol}@depth`)
      ws.current.addEventListener("open", (e) => {
        console.log("connection opened")
      });

      ws.current.addEventListener("message", (event) => {
        let _msg = JSON.parse(event.data)
        if (_msg.data.e === "depthUpdate") {
          let _ob = {
            ask: _msg.data.a,
            bid: _msg.data.b,
          }
          // console.log(_ob)
          setOrderBook(_ob)
        }

        if (_msg.data.e === "24hrTicker") {
          let _st = {
            change: _msg.data["p"],
            change_percent: _msg.data["P"],
            high: _msg.data["h"],
            low: _msg.data["l"],
            open: _msg.data["o"],
            close: _msg.data["c"],
            base_volume: _msg.data["v"],
            quote_volume: _msg.data["q"],
            symbol: _msg.data["s"],
          }
          // console.log(_ob)
          setSymbol24HrTicker(_st)
        }
        // console.log(_msg.data)
      });

      ws.current.addEventListener("error", (event) => {
        console.log("WebSocket error: ", event);
      });

      ws.current.addEventListener("ping", (e) => {
        ws.current.pong();
      });

    // } else {
    //   isRendered.current = true
    // }

    return () => {
      ws.current && ws.current.close()
    }
  }, [symbol])

  return (
    <Box style={{ backgroundColor: 'black' }}>

      <TraderSymbolHeader symbol24HrTicker={symbol24HrTicker} />

      <Grid templateRows={'repeat(1, 1fr)'} templateColumns={'repeat(4, 1fr)'} style={{ height: 'inherit' }} bgColor={'rgb(19, 23, 34)'}>

        <GridItem colSpan={[4, 4, 3, 3]} style={{ height: 'inherit' }}>

          <Grid templateRows={'repeat(1, 1fr)'} templateColumns={'repeat(6, 1fr)'} style={{ height: 'inherit' }}>

            <GridItem display={{ base: 'none', md: 'block' }} colSpan={[0, 0, 2, 2]} bg={'blue'} style={{ height: 'inherit' }} bgColor={'rgb(19, 23, 34)'} >

              <Box ml={3}>

                <OrderBook orderBook={orderBook} />

              </Box>

            </GridItem>

            <GridItem colSpan={[6, 6, 4, 4]} style={{ height: 'inherit' }}>

              <Box h={{ base: '100vh', md: '60vh' }} mt={{ base: '0px', md: '1px' }} pl={2} pr={2} shadow={'xl'}>

                <TvMainChart symbol={symbol.toUpperCase()} interval='30m' />

              </Box>

              <TraderOrders />

            </GridItem>

          </Grid>

        </GridItem>

        <GridItem display={{ base: 'none', md: 'block' }} colSpan={[0, 0, 1, 1]} bg={'blue'} style={{ height: 'inherit' }} bgColor={'rgb(19, 23, 34)'} >

          <Box mb={2}>

            <TradeCard />

          </Box>

          <Box>

            <Watchlist />

          </Box>

        </GridItem>

      </Grid>

      <Hide above='md'>
        <Box
          w={'330px'} h={'auto'}
          bgColor={'transparent'}
          bottom={'80px'} pos={'fixed'} zIndex={3} left={'calc(50% - 330px/2)'}
          display={'flex'} >

          <MobileMarketInfoDrawer symbol={symbol} orderBook={orderBook} />

          <MobileTradeDrawer symbol={symbol} />

        </Box>
      </Hide>

    </Box>

  )

}

export default Trader