import { useState } from 'react'
import { Box, Container, Heading, Flex, Square, Image, Text } from '@chakra-ui/react'
import notfound from '../../assets/images/notfound.png'

function Orders() {

  const [tab, setTab] = useState('opened');

  return (
    <>
      <Container maxW={'container.lg'}>
        <Box h={'100vh'} mt={{ base: 5, md: 10 }}>
          <Flex justifyContent={'space-between'}>
            <Heading size={'lg'} display={'inline-block'} pb={2} mb={2}>Orders</Heading>
            <Flex>
              <Box mr={5}>
                <Box borderBottom={tab === 'opened' ? 'solid 2px #fff' : 'none'} color={tab === 'opened' ? '#fff' : 'rgba(240,240,240,0.6)'} pb={2} cursor={'pointer'} onClick={() => setTab('opened')}>Opened</Box>
              </Box>
              <Box mr={2}>
                <Box borderBottom={tab === 'closed' ? 'solid 2px #fff' : 'none'} color={tab === 'closed' ? '#fff' : 'rgba(240,240,240,0.6)'} pb={2} cursor={'pointer'} onClick={() => setTab('closed')}>Closed</Box>
              </Box>
            </Flex>
          </Flex>

          <Square h={'500px'} mt={{base: '0px', md: '100px'}}>
            <Box>
              <Text textAlign={'center'} pb={3} borderBottom={'solid 1px rgba(230, 230, 230, 0.2)'}>No record found !</Text><br />
              <Image src={notfound} w={{ base: '300px', md: '500px' }} />
            </Box>
          </Square>
        </Box>
      </Container>
    </>
  )
}

export default Orders