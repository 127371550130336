
import React, { useState } from 'react'
import { Box, Button, Card, CardBody, Flex, FormControl, Input, InputGroup, InputRightAddon, SimpleGrid, Text } from '@chakra-ui/react'
import style from '../assets/modules/tradercard.module.css'

function TradeCard({ symbol }) {

  const [activeExecutionType, setActiveExecutionType] = useState('market')

  return (
    <Card bgColor={'rgb(19, 23, 34)'} color={'whiteAlpha.800'} shadow={'dark-lg'}>
      <CardBody>
        <Box>
          <SimpleGrid columns={2} mb={4}>
            <button className={activeExecutionType === "market" ? style.activeexecutiontype : style.inactiveexecutiontype} onClick={() => { setActiveExecutionType("market"); }}>Market</button>
            <button className={activeExecutionType === "limit" ? style.activeexecutiontype : style.inactiveexecutiontype} onClick={() => { setActiveExecutionType("limit"); }}>Limit</button>
          </SimpleGrid>

          {
            activeExecutionType === "market" ?

              // market Box
              <Box>

                <Box mt={6} mb={3}>
                  <FormControl>
                    <Text mb={3}>Price</Text>
                    <InputGroup>
                      <Input variant={'unstyled'} type='number' value={27280} bgColor={'rgba(255,255,255,0.1)'} p={'2px 10px'} placeholder={'0.00'} disabled/>
                      <InputRightAddon children='@Market USDT' bgColor={'rgba(255,255,255,0.1)'} border={'none'} />
                    </InputGroup>
                  </FormControl>
                </Box>

                <Box mt={6} mb={4}>
                  <FormControl>
                    <Text mb={3}>Amount</Text>
                    <InputGroup>
                      <Input variant={'unstyled'} type='number' bgColor={'rgba(255,255,255,0.1)'} p={'2px 10px'} placeholder={'0.00'} />
                      <InputRightAddon children='BTC' bgColor={'rgba(255,255,255,0.1)'} border={'none'} />
                    </InputGroup>
                  </FormControl>
                </Box>

                <hr style={{ borderColor: 'rgba(255,255,255,0.3)' }} />

                <Box mt={3} fontSize={'14px'} mb={4}>

                  <Flex justifyContent={'space-between'} mb={1}>
                    <Flex>
                      <Text>Total</Text>
                      <Text>&nbsp;</Text>
                      <Text color={'rgba(255,255,255,0.5)'}>(USDT)</Text>
                    </Flex>
                    <Text>0.0000000000</Text>
                  </Flex>

                  <Flex justifyContent={'space-between'} mb={1}>
                    <Flex>
                      <Text>Commission</Text>
                      <Text>&nbsp;</Text>
                      <Text color={'rgba(255,255,255,0.5)'}>(0.30%)</Text>
                    </Flex>
                    <Text>0.0000000000</Text>
                  </Flex>

                </Box>

                <SimpleGrid columns={2} gap={2} mb={3}>
                  <Button colorScheme={'green'}>Buy</Button>
                  <Button colorScheme={'red'}>Sell</Button>
                </SimpleGrid>
              </Box> :

              // Limit Box
              <Box>

                <Box mt={6} mb={3}>
                  <FormControl>
                    <Text mb={3}>Price</Text>
                    <InputGroup>
                      <Input variant={'unstyled'} type='number' bgColor={'rgba(255,255,255,0.1)'} p={'2px 10px'} placeholder={'0.00'} />
                      <InputRightAddon children='@USDT' bgColor={'rgba(255,255,255,0.1)'} border={'none'} />
                    </InputGroup>
                  </FormControl>
                </Box>

                <Box mb={4}>
                  <FormControl>
                    <Text mb={3}>Amount</Text>
                    <InputGroup>
                      <Input variant={'unstyled'} type='number' bgColor={'rgba(255,255,255,0.1)'} p={'2px 10px'} placeholder={'0.00'} />
                      <InputRightAddon children='BTC' bgColor={'rgba(255,255,255,0.1)'} border={'none'} />
                    </InputGroup>
                  </FormControl>
                </Box>

                <hr style={{ borderColor: 'rgba(255,255,255,0.3)' }} />

                <Box mt={3} fontSize={'14px'} mb={4}>

                  <Flex justifyContent={'space-between'} mb={1}>
                    <Flex>
                      <Text>Total</Text>
                      <Text>&nbsp;</Text>
                      <Text color={'rgba(255,255,255,0.5)'}>(USDT)</Text>
                    </Flex>
                    <Text>0.0000000000</Text>
                  </Flex>

                  <Flex justifyContent={'space-between'} mb={1}>
                    <Flex>
                      <Text>Commission</Text>
                      <Text>&nbsp;</Text>
                      <Text color={'rgba(255,255,255,0.5)'}>(0.30%)</Text>
                    </Flex>
                    <Text>0.0000000000</Text>
                  </Flex>

                </Box>

                <SimpleGrid columns={2} gap={2} mb={3}>
                  <Button colorScheme={'green'}>Buy</Button>
                  <Button colorScheme={'red'}>Sell</Button>
                </SimpleGrid>
              </Box>
          }
        </Box>
      </CardBody>
    </Card>
  )
}

export default TradeCard