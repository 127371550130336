
import style from '../assets/modules/trader.module.css'
import { Box, Flex, Text, FormControl, Input, InputGroup, InputRightAddon, Icon, Show } from '@chakra-ui/react'
import { MdOutlineManageSearch } from 'react-icons/md'
import { numberFormat } from '../utils/helper'

function TraderSymbolHeader({ symbol24HrTicker }) {
    return (
        <Box className={style.symbolInfoHeader} color={'whiteAlpha.800'} mb={{ base: 'none', md: 1 }}>
            {
                symbol24HrTicker !== null ?
                    <Flex>
                        <Box mr={10}>
                            <FormControl>
                                <InputGroup>
                                    <Input
                                        variant={'unstyled'}
                                        type='text'
                                        bgColor={'rgba(255,255,255,0.1)'} p={'2px 10px'}
                                        value={symbol24HrTicker.symbol}
                                        placeholder={'Search pair'}
                                        color={'whiteAlpha.600'}
                                        w={{ base: '100px', md: '200px' }}
                                        readOnly />
                                    <InputRightAddon children={<Icon as={MdOutlineManageSearch} />} bgColor={'rgba(255,255,255,0.1)'} border={'none'} />
                                </InputGroup>
                            </FormControl>
                        </Box>
                        <Show above='md'>
                            <Box mr={10} borderRight={'1px solid #999'} pr={8}>
                                <Text fontSize={10} color={'whiteAlpha.600'}>symbol</Text>
                                <Text fontSize={14}>{symbol24HrTicker.symbol}</Text>
                            </Box>
                        </Show>
                        <Box mr={10}>
                            <Text fontSize={10} color={'whiteAlpha.600'}>24h Price</Text>
                            <Text fontSize={14}>{numberFormat(symbol24HrTicker.close)}</Text>
                        </Box>
                        <Box mr={10}>
                            <Text fontSize={10} color={'whiteAlpha.600'}>24h Change</Text>
                            <Box fontSize={12}>
                                <Flex>
                                    <Text color={Number(symbol24HrTicker.change) < 0 ? 'red.500' : 'green.500'}>{numberFormat(symbol24HrTicker.change)}</Text>
                                    <Text>&nbsp;</Text>
                                    <Text color={Number(symbol24HrTicker.change_percent) < 0 ? 'red.500' : 'green.500'}>{numberFormat(symbol24HrTicker.change_percent)}%</Text>
                                </Flex>
                            </Box>
                        </Box>
                        <Box mr={10}>
                            <Text fontSize={10} color={'whiteAlpha.600'}>24h High</Text>
                            <Text fontSize={14}>{numberFormat(symbol24HrTicker.high)}</Text>
                        </Box>
                        <Box mr={10}>
                            <Text fontSize={10} color={'whiteAlpha.600'}>24h Low</Text>
                            <Text fontSize={14}>{numberFormat(symbol24HrTicker.low)}</Text>
                        </Box>
                        <Box pr={8}>
                            <Text fontSize={10} color={'whiteAlpha.600'}>24h Volume(BTCUSD)</Text>
                            <Flex>
                                <Text fontSize={13}>{numberFormat(symbol24HrTicker.base_volume)}</Text>
                                <Text>&nbsp;|&nbsp;</Text>
                                <Text fontSize={13}>{numberFormat(symbol24HrTicker.quote_volume)}</Text>
                            </Flex>
                        </Box>
                    </Flex>
                    : <Box>Please wait ...</Box>
            }
        </Box>
    )
}

export default TraderSymbolHeader