import { useState } from 'react'
import { Box, Container, Heading, Flex, Square, Image, Text, Center } from '@chakra-ui/react'
import signin from '../../assets/images/signin.png'
import { FaExclamationCircle } from 'react-icons/fa'

function Wallet() {

  const [tab, setTab] = useState('overview');

  return (
    <>
      <Container maxW={'container.lg'}>
        <Box h={'100vh'} mt={{ base: 5, md: 10 }}>
          <Flex justifyContent={'space-between'}>
            <Heading size={'lg'} display={'inline-block'} pb={2} mb={2}>Wallet</Heading>
            <Flex>
              <Box mr={5}>
                <Box borderBottom={tab === 'overview' ? 'solid 2px #fff' : 'none'} color={tab === 'overview' ? '#fff' : 'rgba(240,240,240,0.6)'} pb={2} cursor={'pointer'} onClick={() => setTab('overview')}>Overview</Box>
              </Box>
              <Box mr={5}>
                <Box borderBottom={tab === 'deposit' ? 'solid 2px #fff' : 'none'} color={tab === 'deposit' ? '#fff' : 'rgba(240,240,240,0.6)'} pb={2} cursor={'pointer'} onClick={() => setTab('deposit')}>Deposit</Box>
              </Box>
              <Box mr={2}>
                <Box borderBottom={tab === 'withdraw' ? 'solid 2px #fff' : 'none'} color={tab === 'withdraw' ? '#fff' : 'rgba(240,240,240,0.6)'} pb={2} cursor={'pointer'} onClick={() => setTab('withdraw')}>Withdraw</Box>
              </Box>
            </Flex>
          </Flex>

          <Square h={'200px'} mt={{ base: '-20px', md: '100px' }}>
            <Box>
              <Center>
                <Flex>
                  <Text textAlign={'center'} mr={2}>Page restricted</Text> <Text mt={1}><FaExclamationCircle /></Text>
                </Flex>
              </Center><br />
              <Text textAlign={'center'} mt={'-20px'} pb={3} borderBottom={'solid 1px rgba(230, 230, 230, 0.2)'}>Kindly login to gain access</Text><br />
              <Image 
                src={signin} 
                pos={'fixed'}
                left={{base:'20px', md: 'calc(50% - 500px/2)'}}
                w={{ base: '400px', md: '500px' }} />
            </Box>
          </Square>
        </Box>
      </Container>
    </>
  )
}

export default Wallet