import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'

import AuthLayout from './layout/AuthLayout'
import UserLayout from './layout/UserLayout'

import Home from './pages/Home'
import NotFound from './pages/NotFoundPage'

import Login from './pages/auth/Login'
import Register from './pages/auth/Register'

import Trader from './pages/user/Trader'
import Analytics from './pages/user/Analytics'
import Orders from './pages/user/Orders'
import Wallet from './pages/user/Wallet'
import Account from './pages/user/Account'

function App() {
  return (
    <Routes>

      <Route path='' element={<Navigate to={'/user/trader'} />} />

      <Route path='auth' element={<AuthLayout/>}>
        <Route path='' element={<Navigate to={'/auth/login'} />} ></Route>
        <Route path='login' element={<Login />} />
        <Route path='register' element={<Register />} />
      </Route>

      <Route path='user' element={<UserLayout />}>
        <Route path='' element={<Navigate to={'/user/trader'} />} ></Route>
        <Route path='trader' element={<Trader />} />
        <Route path='analytics' element={<Analytics />} />
        <Route path='orders' element={<Orders />} />
        <Route path='wallet' element={<Wallet />} />
        <Route path='account' element={<Account />} />
      </Route>

      <Route path='/*' element={<NotFound />} />

    </Routes>
  )
}

export default App