import React from 'react'
import { Outlet } from 'react-router-dom'

function AuthLayout() {
    return (
        <>
            <p>Auth Layout</p>
            <Outlet />
        </>
    )
}

export default AuthLayout