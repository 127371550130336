// TradingViewWidget.jsx
import React, { useEffect, useRef, memo } from 'react';

function TradingViewWidget() {
  const contariner = useRef();
  // const isRendered = useRef(false)

  useEffect(
    () => {
      const script = document.createElement("script");
      // if (isRendered.current === true) {
        script.src = "https://s3.tradingview.com/external-embedding/embed-widget-ticker-tape.js";
        script.type = "text/javascript";
        script.async = true;
        script.innerHTML = `
        {
          "symbols": [
            {
              "proName": "Binance:BTCUSDT",
              "title": "Bitcoin"
            },
            {
              "proName": "Binance:ETHUSDT",
              "title": "Ethereum"
            },
            {
              "proName": "Binance:BNBUSDT",
              "title": "Binance Coin"
            },
            {
              "proName": "Binance:XRPUSDT",
              "title": "Ripple"
            },
            {
              "proName": "Binance:ADAUSDT",
              "title": "Cardano"
            },
            {
              "proName": "Binance:DOGEUSDT",
              "title": "Dogecoin"
            },
            {
              "proName": "Binance:MATICUSDT",
              "title": "Polygon"
            },
            {
              "proName": "Binance:UNIUSDT",
              "title": "Uniswap"
            }
          ],
            "showSymbolLogo": true,
              "colorTheme": "dark",
                "isTransparent": false,
                  "displayMode": "adaptive",
                    "locale": "en"
        }`;
        contariner.current.appendChild(script);
      // } else {
      //   isRendered.current = true;
      // }

      return () => {
        script.remove()
      };
    },
    []
  );

  return (
    <div className="tradingview-widget-container" ref={contariner}>
      <div className="tradingview-widget-container__widget"></div>
    </div>
  );
}

export default memo(TradingViewWidget);
