
import { Outlet } from 'react-router-dom'
import { Box } from '@chakra-ui/react'
import NavHeader from '../components/NavHeader'

function UserLayout() {

    return (
        <Box bgColor={'rgb(19, 23, 34)'} color={'whiteAlpha.800'} w={'100%'}>      

            <NavHeader />      

            <Box pt={'80px'}>
                <Outlet />
            </Box>
            
        </Box>
    )
}

export default UserLayout