import { Box, Card, CardBody, Flex, Heading, Switch, Text } from '@chakra-ui/react'
import React from 'react'

function Settings() {
    return (
        <>
            <Heading fontSize={'18px'} mb={5} mt={10}>Settings</Heading>

            <Card bgColor={'rgb(19, 23, 34)'} color={'whiteAlpha.800'} shadow={'dark-lg'} mb={10}>
                <CardBody>
                    <Flex justifyContent={'space-between'}>
                        <Box>
                            <Heading display={'inline-block'} borderBottom={'solid 2px #fff'} pb={2} size={'sm'} mb={2}>2FA</Heading>
                            <Text w={{ base: '100%', md: '50%' }}>Secure your account with two factor authentication. Enable this to authenticate every login with a one time pin sent to your email</Text>
                        </Box>
                        <Box>
                            <Switch type={'switch'} isChecked />
                        </Box>
                    </Flex>
                </CardBody>
            </Card>

            <Card bgColor={'rgb(19, 23, 34)'} color={'whiteAlpha.800'} shadow={'dark-lg'} mb={10}>
                <CardBody>
                    <Flex justifyContent={'space-between'}>
                        <Box>
                            <Heading display={'inline-block'} borderBottom={'solid 2px #fff'} pb={2} size={'sm'} mb={2}>Notifications</Heading>
                            <Text w={{ base: '100%', md: '50%' }}>Receive transaction notifications in your registered email address. Enable this to receive prompt notifications for every transaction made on your account</Text>
                        </Box>
                        <Box>
                            <Switch type={'switch'} isChecked />
                        </Box>
                    </Flex>
                </CardBody>
            </Card>

            <Card bgColor={'rgb(19, 23, 34)'} color={'whiteAlpha.800'} shadow={'dark-lg'}>
                <CardBody>
                    <Flex justifyContent={'space-between'}>
                        <Box>
                            <Heading display={'inline-block'} borderBottom={'solid 2px #fff'} pb={2} size={'sm'} mb={2}>Newsletter</Heading>
                            <Text w={{ base: '100%', md: '50%' }}>Subscribe to crypto.com newsletter to stay up to date on our promotions and services. Be the first to jump on any deal.</Text>
                        </Box>
                        <Box>
                            <Switch type={'switch'} />
                        </Box>
                    </Flex>
                </CardBody>
            </Card>
            
        </>
    )
}

export default Settings